import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
    <Layout>
    <section className="section section--gradient">
        <div className="container">
            <div className="columns">
                <div className="column is-10 is-offset-1">
                    <div className="section">
                        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                            404 - Oops, this page has gone missing <span>🤷🏻‍♂️</span>
                        </h2>
                        <img src="/img/404.png" alt="Page not found"/>
                         Image by <a href="https://pixabay.com/users/jessica45-2055892/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1444975">jessica45</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1444975">Pixabay</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </Layout>
)

export default NotFoundPage
